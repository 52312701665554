<template>
  <h4></h4>
  <div class="p-grid" style="max-width: 1200px; min-width: 455px">
    <div class="p-col-12 p-md-12 p-lg-8">
      <Card class="p-p-3">
        <template #title>EOD Details</template>
        <template #subtitle>
          <div class="p-d-flex p-ai-center" style="gap: 0.5rem">
            <b>How did you feel today?: <span style="font-size: 1.2rem" v-html="smiley"></span></b>
            <p>
              <span><b>Help Needed?: </b></span> {{ basicInfo('instructions') }}
            </p>
          </div>
        </template>
        <template #content>
          <FieldDetail title="Today Overview" type="html" :loading="loading" :value="basicInfo('todayOverview')" />
          <FieldDetail
            title="What was most important today?"
            type="html"
            :loading="loading"
            :value="basicInfo('mostImportant')"
          />
          <FieldDetail title="Next Shift" type="html" :loading="loading" :value="basicInfo('nextShift')" />
        </template>
      </Card>

      <KPIDetail class="p-mt-3" v-for="kpi in kpis" :key="kpi.taskId" :kpi="kpi" />
    </div>
    <div class="p-col-12 p-md-12 p-lg-4">
      <ReportMeta :loading="loading" :report="report" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import ReportMeta from '../../components/reporting/detail/ReportMeta.vue'
import FieldDetail from '../../components/reporting/detail/FieldDetail.vue'
import KPIDetail from '../../components/reporting/detail/KPIDetail.vue'
import useReportDetail from '../../hooks/useReportDetail'

const props = defineProps({
  reportId: { type: [Number, String], required: true }
})

const store = useStore()
const { loading, report } = useReportDetail(props.reportId)

const kpis = computed(() => report.value?.data?.kpis || [])
const smiley = computed(() => store.getters.getSmileys(basicInfo('howsToday') - 1))

const basicInfo = (field, defaultValue = '') => (!report.value ? defaultValue : report.value.data.overview[field])
</script>
